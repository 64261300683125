import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Event } from '../../models/event.model';
import { Page } from '../../models/page.model';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-curiousfestival',
  templateUrl: './curiousfestival.component.html',
  styleUrls: ['./curiousfestival.component.css']
})
export class CuriousfestivalComponent implements OnInit {

  events2022YearRef:AngularFirestoreCollection<Event>;
  events2022Year$:Observable<Event[]>;

  events2020YearRef:AngularFirestoreCollection<Event>;
  events2020Year$:Observable<Event[]>;

  events2019YearRef:AngularFirestoreCollection<Event>;
  events2019Year$:Observable<Event[]>;

  events2018YearRef:AngularFirestoreCollection<Event>;
  events2018Year$:Observable<Event[]>;

  eventDocRef:AngularFirestoreDocument<Event>;
  saveState:string = "done";
  dropVeil : string;
  itsOpened = new Array();

  pagesRef:AngularFirestoreCollection<Page>;
  page$:Observable<Page[]>;

  constructor(private afs:AngularFirestore) { 
    this.dropVeil = "hideVeil";
    const settings = {timestampsInSnapshots: true};
    afs.firestore.settings(settings);
    
    // read all events from all years..
    this.events2022YearRef = this.afs.collection('events',ref => ref.where('StartDateTime',">",new Date('Jan 01, 2022 00:00:00 UTC+0100')).where('StartDateTime',"<",new Date('Jan 01, 2023 00:00:00 UTC+0100')).orderBy('StartDateTime'));
    this.events2022Year$ = this.events2022YearRef.valueChanges();
    
    // read all events from all years..
    this.events2020YearRef = this.afs.collection('events',ref => ref.where('StartDateTime',">",new Date('Jan 01, 2020 00:00:00 UTC+0100')).where('StartDateTime',"<",new Date('Jan 01, 2021 00:00:00 UTC+0100')).orderBy('StartDateTime'));
    this.events2020Year$ = this.events2020YearRef.valueChanges();
    
   // read all events from all years..
    this.events2019YearRef = this.afs.collection('events',ref => ref.where('StartDateTime',">",new Date('Jan 01, 2019 00:00:00 UTC+0100')).where('StartDateTime',"<",new Date('Jan 01, 2020 00:00:00 UTC+0100')).orderBy('StartDateTime'));
    this.events2019Year$ = this.events2019YearRef.valueChanges();
    
    // content..
    this.pagesRef = this.afs.collection('pages',ref => ref.where('title',"==","Curious Festival"));
    this.page$ = this.pagesRef.valueChanges();

  }

  ngOnInit() {
  }


  openEvent(ev){
    console.log("opening"+ev);
    this.itsOpened[ev]="block";
    this.dropVeil="showVeil";
  //  TMgotoClass("container",0.5);
  }
  closeEvent(){
    this.itsOpened=new Array();
    this.dropVeil="hideVeil";
  }  

}
