import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { News } from '../../models/news.model';
import { Page } from '../../models/page.model';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  newsRef:AngularFirestoreCollection<News>;
  new$:Observable<News[]>;
  NewsDocRef:AngularFirestoreDocument<News>;
  saveState:string = "done";
  dropVeil : string;
  itsOpened = new Array();
  currentYear: number;

  newsletterOpened:string = 'none';


  //
  pagesRef:AngularFirestoreCollection<Page>;
  page$:Observable<Page[]>;

  constructor(private afs:AngularFirestore){
    this.dropVeil = "hideVeil";
    const settings = {timestampsInSnapshots: true};
    afs.firestore.settings(settings);

    // latest 25..
    this.newsRef = this.afs.collection('news',ref => ref.where('pubDateDay',"<=",new Date().toISOString().split('T')[0]).orderBy('pubDateDay','desc').limit(25));
    this.new$ = this.newsRef.valueChanges();

    // content..
    this.pagesRef = this.afs.collection('pages',ref => ref.where('title',"==","News"));
    this.page$ = this.pagesRef.valueChanges();

  }


  ngOnInit() {
  }

  closeNewsletter(){
    this.newsletterOpened='none';
  }

  openNewsletter(){
    this.newsletterOpened='block';
    window.scrollTo(0, 0);
  }

}
