import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { CuriousfestivalComponent } from './curiousfestival/curiousfestival.component';
import { TouringworksComponent } from './touringworks/touringworks.component';
import { ProjectsComponent } from './projects/projects.component';
import { GalleryComponent } from './gallery/gallery.component';
import { NewsComponent } from './news/news.component';
import { QanComponent } from './qan/qan.component';
import { TrainingComponent } from './training/training.component';

import { AdminNewsComponent } from './admin/news/admin-news.component';
import { AdminLoginComponent } from './admin/login/admin-login.component';
import { AdminPageComponent } from './admin/pages/admin-page.component';
import { AdminEventsComponent } from './admin/events/admin-events.component';

import { AppRoutingModule } from './/app-routing.module';
import { MomentModule } from 'ngx-moment';

import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { environment } from '../environments/environment.prod';

import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { FormsModule } from '@angular/forms';

import { CKEditorModule } from 'ng2-ckeditor';
import { AuthGuard } from './_guards/auth.guard';
import { DropZoneDirective } from './drop-zone.directive';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutusComponent,
    CuriousfestivalComponent,
    TouringworksComponent,
    ProjectsComponent,
    GalleryComponent,
    NewsComponent,
    QanComponent,
    TrainingComponent,
    AdminNewsComponent,
    AdminLoginComponent,
    AdminPageComponent,
    AdminEventsComponent,
    SafeHtmlPipe,
    DropZoneDirective,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,    
    AngularFireAuthModule,
    AngularFireStorageModule,
    BrowserModule,
    AppRoutingModule,
    MomentModule,
    FormsModule,
    CKEditorModule
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
