export const environment = {
  production: true,
  timestampsInSnapshots: true,
  firebase: {
    apiKey: "AIzaSyCnb5Xk-YNNEv2HlwXDQwMV3OVWjE9jnpM",
    authDomain: "curious-63879.firebaseapp.com",
    databaseURL: "https://curious-63879.firebaseio.com",
    projectId: "curious-63879",
    storageBucket: "curious-63879.appspot.com",
    messagingSenderId: "663183830416"
  }
};
