import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Page } from '../../models/page.model';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

    pagesRef:AngularFirestoreCollection<Page>;
    page$:Observable<Page[]>;
  
    constructor(private afs:AngularFirestore) {
  
      const settings = {timestampsInSnapshots: true};
      afs.firestore.settings(settings);
  
      // content..
      this.pagesRef = this.afs.collection('pages',ref => ref.where('title',"==","Projects"));
      this.page$ = this.pagesRef.valueChanges();
  
     }  

  ngOnInit() {

  }

}
