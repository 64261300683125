import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireStorage, AngularFireUploadTask } from 'angularfire2/storage';
import { News } from '../../../models/news.model';
import { Page } from '../../../models/page.model';
import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-admin-news',
  templateUrl: './admin-news.component.html',
  styleUrls: ['./admin-news.component.css']
})
export class AdminNewsComponent implements OnInit {

  newsRef:AngularFirestoreCollection<News>;
  new$:Observable<News[]>;
  NewsDocRef:AngularFirestoreDocument<News>;

  saveState:string = "done";
  editingNews:string = "";
  addingNews:boolean = false;
  newR:News;
  deleteR:string;
  copyR:News;
  showCustom:boolean = false;
  cr:any;
  dropVeil : string;
  itsOpened = new Array();
  currentYear: number;
  cKEditorContent: string = "text";
  ckeConfig: any;

  // upload vars...
  task: AngularFireUploadTask;
  ref:any;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: Observable<string>;
  isHovering: boolean;
  uploadStatus:string = "";
  uploadObject:any;

  //
  pagesRef:AngularFirestoreCollection<Page>;
  page$:Observable<Page[]>;

  constructor(private afs:AngularFirestore, private storage: AngularFireStorage){
    this.dropVeil = "hideVeil";
    const settings = {timestampsInSnapshots: true};
    afs.firestore.settings(settings);

    // grab all stories..
    this.newsRef = this.afs.collection('news',ref => ref.orderBy('pubDate','desc'));
    this.new$ = this.newsRef.valueChanges();

    // content..
    this.pagesRef = this.afs.collection('pages',ref => ref.where('title',"==","News"));
    this.page$ = this.pagesRef.valueChanges();

    this.cleanNews();
    this.uploadStatus = "";

  }


  ngOnInit() {

    this.ckeConfig = {
      height: 400,
      language: "en",
      allowedContent: true,
      contentsCss: '/assets/editor_styles.css',

      toolbar: [
          { name: "editing", items: ["Find", "Replace", "SelectAll"] },
          { name: "clipboard", items: ["Cut", "Copy", "Paste", "PasteText", "PasteFromWord", "-", "Undo", "Redo"] },
          { name: "links", items: ["Link", "Unlink", "Anchor"] },
          { name: "tools", items: ["Maximize", "ShowBlocks"] },
          { name: "insert", items: ["Table", "HorizontalRule", "SpecialChar", "Iframe", "imageExplorer"] },
          { name: "document", items: ["Source"] },
          "/",
          { name: "basicstyles", items: ["Bold", "Italic", "Underline", "Strike", "Subscript", "Superscript", "-", "RemoveFormat"] },
          { name: "paragraph", items: ["NumberedList", "BulletedList"] },
          { name: "justify", items: ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"] },
          { name: "styles", items: ["Styles", "Format", "FontSize", "-", "TextColor", "BGColor"] }
      ]
  };

  }

// ===========================


  // ==================================================================
  // new news...

  cleanNews(){
  
    this.newR = {  
    id: '', 
    title: '', 
    titleSub: '',
    titleSlug:'', 
    content: '',
    metaKey:'',
    categoryMain: '',
    categorySub: '',
    videoURL: [],
    linkURL: [{URL:'',title:''}],
    imgURL: [],
    pubDate: new Date(),
    pubDateDay: new Date().toISOString().split('T')[0],
    pubDateTime: new Date().toLocaleTimeString(),
    pubBy:''}

  }

  addNews(){
    this.addingNews=true;
  }

  setNewNews(){
    this.saveState="busy";
    this.newR.id = this.afs.createId();
    this.NewsDocRef = this.afs.doc('news/'+this.newR.id);
    this.NewsDocRef.set(this.newR).then(e => {
      this.saveState="done";
      this.editingNews="";
      }
    )    
    // once we're saved...
    this.addingNews=false;
    this.cleanNews();
  }

  cancelNewNews(){
    this.addingNews=false;
    this.cleanNews();
  }


// ==================================================================
// existing news...


  setNews(news: News){
    this.saveState="busy";

    this.NewsDocRef = this.afs.doc('news/'+news.id);
    this.NewsDocRef.update(news).then(e => {
      this.saveState="done";
      this.editingNews="";
      }
    )
  }

  editNews(news){
    this.editingNews=news;
    this.deleteR="";
    this.addingNews=false;

    // fetch additional children copies...

  }

// ==================================================================
// deletions...
  
  cancelDeleteNews(news){
    this.deleteR="";
  }

  deleteConfirm(news){
    this.editingNews="";
    this.deleteR = news;
  }

  deleteNews(news){
    this.NewsDocRef = this.afs.doc('news/'+news);
    this.NewsDocRef.delete().then(e => {
      this.saveState="done";
      this.editingNews="";
      this.deleteR="";
      }
    )
  }

// ======================================================
// file upload and manipulation....

  
toggleHover(event: boolean) {
   this.isHovering = event;
}

startUpload(event: FileList,newsItem) {
    // The File object
    const file = event.item(0);
    this.uploadStatus = "in progress";
    this.uploadObject = newsItem;

    // Client-side validation example
    if (file.type.split('/')[0] !== 'image') { 
      console.error('unsupported file type :( ')
      return;
    }

    // The storage path
    const path = `uploads/news/${new Date().getTime()}_${file.name}`;

    // Totally optional metadata
    const customMetadata = { app: 'Curious Uploader' };

    // The main task
    this.task = this.storage.upload(path, file, { customMetadata });
    this.ref  = this.storage.ref(path);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();
    this.snapshot   = this.task.snapshotChanges();

    this.snapshot.pipe(
        finalize(() => {
          this.downloadURL = this.ref.getDownloadURL();
          this.downloadURL.subscribe(url => {
            this.uploadObject.imgURL[0]=url;
            this.uploadStatus = "";
          });
        })
      )
      .subscribe();

  }

  // Determines if the upload task is active
  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes
  }


}
