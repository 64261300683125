import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Event } from '../../models/event.model';
import { Page } from '../../models/page.model';
import { Observable } from 'rxjs/Observable';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  eventsRef:AngularFirestoreCollection<Event>;
  event$:Observable<Event[]>;
  eventDocRef:AngularFirestoreDocument<Event>;
  saveState:string = "done";
  dropVeil : string;
  itsOpened = new Array();
  currentYear: number;

  //
  pagesRef:AngularFirestoreCollection<Page>;
  page$:Observable<Page[]>;

  ptSub:any;

  newsletterOpened:string = 'none';

  //
  constructor(private afs:AngularFirestore, private pt: Title, private mt: Meta){
    this.dropVeil = "hideVeil";
    const settings = {timestampsInSnapshots: true};
    afs.firestore.settings(settings);

    // latest 4..
    this.eventsRef = this.afs.collection('events',ref => ref.where('StartDateTime',">",new Date()).orderBy('StartDateTime').limit(4));
    this.event$ = this.eventsRef.valueChanges();

    // content..
    this.pagesRef = this.afs.collection('pages',ref => ref.where('title',"==","Home"));
    this.page$ = this.pagesRef.valueChanges();

    // content title/meta tags...
    this.ptSub = this.page$.subscribe(p => {
     p.forEach(pp => {
       pt.setTitle(pp.metaTitle);
       mt.updateTag({name:"title",content:pp.metaTitle});
       mt.updateTag({name:"description",content:pp.metaDesc});
       mt.updateTag({name:"keywords",content:pp.metaKey});

     })
    });

  }
  
  ngOnDestroy(){
    // unsubscribe from the title/page monitoring...
    this.ptSub.unsubscribe();
  }

  ngOnInit() {
      this.currentYear = new Date().getFullYear();

      /*

      var data: Event[] = [


  
        {
          Title: "QUEER ON THE QUAY INFLATABLE CHURCH OF LOVE",
          SlugTitle: "queer-on-the-quay-inflatable-church-of-love",
          Location: 'BALTIC Square, BALTIC Centre For Contemporary Art, Gateshead, NE8 3BA',
          StartDateTime: new Date("July 07, 2018 11:00:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 08, 2018 17:00:00 UTC+0100"),
          ApproxDuration: 'All day',
          Description: 'Join Curious Arts on Gateshead Quayside for a fantastic weekend of activities in and around Baltic Square. A funky, colourful, inflatable ‘Church of Love’ lands in Baltic Square. Curious knows that love is for everyone, so bring your lover, best friend, dog or anyone that you want to commit to a lifetime of love with (or just an afternoon…) Join a host of themed performers and dress up not down, its everybody’s big day! Ps. Weddings aren’t legally binding but are guaranteed giggles!',
          ShortDescription:'Join Curious Arts on Gateshead Quayside for a fantastic weekend of activities in and around Baltic Square.',
          DescriptionBoxed:'Drop in',        
          TicketDescription: 'FREE (no booking required)',
          TicketType: '',
          TicketLink: '',
          AgeRestrictions: 'All ages',
          ImgPath: ['/assets/images/image-queer-on-the-quay-inflatable-church-of-love-sq.jpg'],
          ImgThumb: ['/assets/images/image-queer-on-the-quay-inflatable-church-of-love-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        }, 
  
    {
          Title: "FESTIVAL HUB AND POP UP GALLERY",
          SlugTitle: "festival-hub-and-pop-up-gallery",
          Location: "Curious Festival Hub, 8-9 Central Arcade, Newcastle, NE1 5AF",
          StartDateTime: new Date("July 02, 2018 11:00:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 08, 2018 14:00:00 UTC+0100"),
          ApproxDuration: 'See description',
          Description: '<p>6:00pm Launch (Mon) 11:00am – 6:00pm (Tues-Fri) 11:00am- 5:00pm (Sat) 11:00am - 3:00pm (Sun)</p><p>Between Monday 2nd – Sunday 8th July, celebrate the diversity of the LGBTQ community at the Curious Festival Hub (8-9 Central Arcade). Experience the work of three Artists based/making work in the North East: Carla B Turner, gobscure and IDa4.  </p>On the Curious Festival stop of its national tour, see 36point7, a HIV/AIDS awareness light installation by Artist and Neon maker Stuart Langley. #36point7</p>',
          ShortDescription:'Celebrate the diversity of the LGBTQ community at the Curious Festival Hub.',
          DescriptionBoxed:'',        
          TicketDescription: 'FREE, no booking required.',
          TicketType: '',
          TicketLink: '',
          AgeRestrictions: 'All',
          ImgPath: ['/assets/images/image-festival-hub-and-pop-up-gallery-sq.jpg'],
          ImgThumb: ['/assets/images/image-festival-hub-and-pop-up-gallery-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
      },
  
          Title: "SLATE IS CURIOUS - PANEL DISCUSSION",
          SlugTitle: "slate-is-curious-panel-discussion",
          Location: 'Northern Stage, Newcastle, NE1 7RH',
          StartDateTime: new Date("July 03, 2018 17:00:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 03, 2018 18:30:00 UTC+0100"),
          ApproxDuration: '1 hour and 30 minutes',
          Description: 'Afshan D’souza-Lodhi chairs a discussion on the issues faced by queer, Black artists based in northern England. Amongst other things the panel will discuss taking work with a strong cultural identity to a wider audience without diluting its integrity. Panellists include: Cheryl Martin and Darren Pritchard. <strong>BSL Interpreted</strong>.',
          ShortDescription:'Afshan D’souza-Lodhi chairs a discussion on the issues faced by queer, Black artists based in northern England.',
          DescriptionBoxed:'<h3>About Slate</h3><p>Slate is an Eclipse movement that will support thousands of Black* Independent Artists in the north of England by increasing access to local, national and international networks. Developed and delivered by the Black arts sector in the North, Slate is funded by an Arts Council England Sustained Theatre Award.</p><p>*To Eclipse, Black includes anyone who is marginalized for their race or ethnicity.</p>',        
          TicketDescription: 'FREE (book via northernstage.co.uk)',
          TicketType: 'book',
          TicketLink: 'https://www.northernstage.co.uk',
          AgeRestrictions: '14yrs+ 14-17yrs to be accompanied by and adult',
          ImgPath: ['/assets/images/image-slate-is-curious-panel-discussion-sq.jpg'],
          ImgThumb: ['/assets/images/image-slate-is-curious-panel-discussion-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        },  
    
        {
          Title: "SEVEN STORIES IS CURIOUS",
          SlugTitle: "seven-stories-is-curious",
          Location: 'Seven Stories, Newcastle, NE1 2PQ',
          StartDateTime: new Date("July 02, 2018 10:00:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 08, 2018 17:00:00 UTC+0100"),
          ApproxDuration: 'All day (see general admission times)',
      
          Description: 'Seven Stories is celebrating LGBTQ+ families all week! Tie a rainbow ribbon to the Tree of Pride, dress up not down with our gender stereotype busting costume box, celebrate your family identity through making a badge or flag and have fun with colourful Curious-themed crafts in our Studio.',
      
          ShortDescription:'Seven Stories is celebrating LGBTQ+ families all week!',
          DescriptionBoxed:'',        
          TicketDescription: 'FREE (with general admission)',
          TicketType: 'book',
          TicketLink: 'https://sevenstories.org.uk',
          AgeRestrictions: 'Suitable for all the family',
          ImgPath: ['/assets/images/image-seven-stories-is-curious-sq.jpg'],
          ImgThumb: ['/assets/images/image-seven-stories-is-curious-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        }, 
          

        {
          Title: "TALK :: SUPPORTING YOUNG PEOPLE AND THEIR GENDER IDENTITY",
          SlugTitle: "supporting-young-people-and-their-gender-identity",
      Location: "Thought Foundation, Birtley, DH3 2TB",
          StartDateTime: new Date("July 01, 2018 11:00:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 01, 2018 12:15:00 UTC+0100"),
          ApproxDuration: '75 mins',
          Description: 'Join a panel of speakers including young people, parents, Curious Arts, LGBT North East Youth Support service and Chalk for an open discussion about gender identity and supporting young people.',
          ShortDescription:'Join a panel of speakers for an open discussion about gender identity and supporting young people.',
          DescriptionBoxed:'',        
          TicketDescription: 'FREE (Booking required)',
          TicketType: 'book',
          TicketLink: 'https://www.eventbrite.co.uk/e/supporting-young-people-and-their-identity-through-the-arts-tickets-46080329506',
          AgeRestrictions: '14yrs+ only. 14-17yrs to be accompanied by an adult',
          ImgPath: ['/assets/images/image-supporting-young-people-and-their-gender-identity-sq.jpg'],
          ImgThumb: ['/assets/images/image-supporting-young-people-and-their-gender-identity-sq.jpg'],
          TextColor: 'curious-red',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-red-bg'
  },
      
  {
          Title: "LIVE THEATRE IS CURIOUS",
          SlugTitle: "live-theatre-is-curious",
          Location: "Live Theatre, Broad Chare, Newcastle, NE1 3DQ",
      
          StartDateTime: new Date("July 01, 2018 18:00:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 01, 2018 20:00:00 UTC+0100"),
          ApproxDuration: '120 mins',
  
          Description: '<p>Curious Festival 2018 will launch with an exciting showcase of work by young people. The evening will explore LGBTQ themes through the ideas and voices of 14 – 25yr olds from across Newcastle & Gateshead.</p><p>Live Theatre is Curious will present a variety of work in a number of forms and stages of development. The evening will showcase work by guest groups and work made during the Curious Queer Creative Challenge programme, a Curious Arts led pilot project.</p>',
          ShortDescription:'Curious Festival 2018 will launch with an exciting showcase of work by young people.',
          DescriptionBoxed:'',        
          TicketDescription: '£5 through livetheatre.co.uk',
          TicketType: 'book',
          TicketLink: 'https://www.live.org.uk/whats-on/live-theatre-curious',
  
          AgeRestrictions: '14yrs+ only. 14-17yrs to be accompanied by an adult',
          ImgPath: ['/assets/images/image-live-theatre-is-curious-sq.jpg'],
          ImgThumb: ['/assets/images/image-live-theatre-is-curious-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
  },
  

  {
          Title: "TALK :: DRAGGED UP IN THE NORTH EAST",
          SlugTitle: "dragged-up-in-the-north-east",
          Location: "St Mary’s Heritage Centre, Gateshead, NE8 2AU",
          StartDateTime: new Date("July 02, 2018 18:00:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 02, 2018 17:15:00 UTC+0100"),
          ApproxDuration: '75 mins',
          Description: 'Join Gary (Ophelia B), Curious Arts, Mutha Tucka & Friends to discuss all things drag! We’ll be talking about the history of Drag in the North East, who inspired who and mapping out a family tree of the region’s Drag social history. Debating what’s traditional, where drag belongs as well as discussing the pro’s and con’s of the Ru generation and everything in between!',
          ShortDescription:'Join Gary (Ophelia B), Curious Arts, Mutha Tucka & Friends to discuss all things drag!',
          DescriptionBoxed:'',        
          TicketDescription: 'FREE (Booking required)',
          TicketType: 'book',
          TicketLink: 'https://www.eventbrite.co.uk/e/dragged-up-in-the-north-east-tickets-46081896192',
          AgeRestrictions: 'All',
          ImgPath: ['/assets/images/image-dragged-up-in-the-north-east-sq.jpg'],
          ImgThumb: ['/assets/images/image-dragged-up-in-the-north-east-sq.jpg'],
          TextColor: 'curious-red',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-red-bg'
  },
  
  {
          Title: "BREEZE CREATIVES EXHIBITIONS",
          SlugTitle: "breeze-creatives-exhibitions",
          Location: "Breeze Creatives, Bamburgh House, Newcastle, NE1 6BH",
          StartDateTime: new Date("July 02, 2018 09:00:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 07, 2018 17:00:00 UTC+0100"),
          ApproxDuration: '',
          Description: 'From Monday 2nd – Saturday 7th July (9am - 5pm), explore representations of the queer body as Breeze Creatives host Visual Arts exhibitions: Bulk Male Flower by James Unworth and Body, you are not me by Newcastle-based Oliver Doe.',
          ShortDescription:'Explore representations of the queer body as Breeze Creatives host Visual Arts exhibitions.',
          DescriptionBoxed:'',        
          TicketDescription: 'FREE (no booking required)',
          TicketType: '',
          TicketLink: '',
          AgeRestrictions: 'All',
          ImgPath: ['/assets/images/image-breeze-creatives-exhibitions-sq.jpg'],
          ImgThumb: ['/assets/images/image-breeze-creatives-exhibitions-sq.jpg'],
          TextColor: 'curious-cyan',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-cyan-bg'
    },		


        {
          Title: "SLATE IS CURIOUS ARTIST SHOWCASE",
          SlugTitle: "slate-is-curious-artist-showcase",
          Location: 'Northern Stage, Newcastle, NE1 7RH',
          StartDateTime: new Date("July 03, 2018 19:45:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 03, 2018 21:15:00 UTC+0100"),
          ApproxDuration: '1 hour and 30 minutes',
          Description: 'Four exciting queer, Black artists from across the North showcase extracts from new projects, including Patrick Ziza’s new show Family Affair. Join us for an evening of outstanding work from performers you need to know more about. More artists to be announced.',
          ShortDescription:'Four exciting queer, Black artists from across the North showcase extracts from new projects.',
          DescriptionBoxed:'<h3>About Slate</h3><p>Slate is an Eclipse movement that will support thousands of Black* Independent Artists in the north of England by increasing access to local, national and international networks. Developed and delivered by the Black arts sector in the North, Slate is funded by an Arts Council England Sustained Theatre Award.</p><p>*To Eclipse, Black includes anyone who is marginalized for their race or ethnicity.</p>',        
          TicketDescription: 'Pay what you decide at nothernstage.co.uk',
          TicketType: 'book',
          TicketLink: 'https://www.northernstage.co.uk',
          AgeRestrictions: '14yrs+ only. 14-17yrs to be accompanied by an adult.',
          ImgPath: ['/assets/images/image-slate-is-curious-artist-showcase-sq.jpg'],
          ImgThumb: ['/assets/images/image-slate-is-curious-artist-showcase-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        },     

        {
          Title: "ICONOGRAPHY (By Zoe Murtagh)",
          SlugTitle: "iconography-by-zoe-murtagh",
          Location: 'Alphabetti Theatre, Newcastle, NE1 4HP',
          StartDateTime: new Date("July 04, 2018 18:30:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 05, 2018 19:10:00 UTC+0100"),
          ApproxDuration: '50 minutes',
          Description: '<p>A celebratory and inquisitive look at the phenomena of ‘gay icons’. Who decides what is iconic? Who allows people to become iconic? And is becoming an icon something we can all achieve? </p><p>A story of desire, gender and celebrity explored through fantasy, road-movie style storytelling and original music. From Marilyn to Madonna to Judy and Gaga, Iconography seeks to explore and critique sexism and misogyny in the gay community today and asks how we can better embrace diversity within queer culture.</p>',
          ShortDescription:'A celebratory and inquisitive look at the phenomena of ‘gay icons’.',
          DescriptionBoxed:'',        
          TicketDescription: '£7 | £10 ticket deal when booked with Dietrich: Natural Duty',
          TicketType: 'book',
          TicketLink: 'https://www.alphabettitheatre.co.uk',
          AgeRestrictions: '16yrs +, 16-17yrs to be accompanied by an adult.',
          ImgPath: ['/assets/images/image-iconography-by-zoe-murtagh-sq.jpg'],
          ImgThumb: ['/assets/images/image-iconography-by-zoe-murtagh-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        }, 
      
        {
          Title: "DIETRICH : NATURAL DUTY (By Peter Groom)",
          SlugTitle: "dietrich-natural-duty-by-peter-groom",
          Location: 'Alphabetti Theatre, Newcastle, NE1 4HP',
          StartDateTime: new Date("July 04, 2018 19:45:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 05, 2018 20:40:00 UTC+0100"),
          ApproxDuration: '50 minutes',
          Description: '<p>It is 1942, on the battlefields of North Africa, in a gold sequin gown, Marlene Dietrich takes to the stage to launch her own offensive against the German Reich. For the next three years she would fight the war her way, with an irresistible mix of songs, sequins, sex and sympathy.</p><p>An intoxicating mix of theatre, cabaret and drag, this new one-(wo)man show by Peter Groom uses music, stories and songs to reveal a Hollywood icon’s extraordinary commitment to duty.</p>',
          ShortDescription:'It is 1942, Marlene Dietrich takes to the stage to launch her own offensive against the German Reich.',
          DescriptionBoxed:'',        
          TicketDescription: '£7 | £10 ticket deal when booked with ICONOGRAPHY',
          TicketType: 'book',
          TicketLink: 'https://www.alphabettitheatre.co.uk',
          AgeRestrictions: '16yrs +, 16-17yrs to be accompanied by an adult.',
          ImgPath: ['/assets/images/image-dietrich-natural-duty-by-peter-groom-sq.jpg'],
          ImgThumb: ['/assets/images/image-dietrich-natural-duty-by-peter-groom-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        }, 
  
        {
          Title: "QUEER ARTS NORTH",
          SlugTitle: "queer-arts-north",
          Location: 'Curious Festival Hub, 8-9 Central Arcade, Newcastle, NE1 5AF',
          StartDateTime: new Date("July 04, 2018 15:00:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 04, 2018 17:00:00 UTC+0100"),
          ApproxDuration: '2 hours',
          Description: 'A panel discussion and networking event hosted by Queer Arts North, a new LGBTQ arts network for the North. This event invites LGBTQ artists, producers and programmers based in/making work across the North to learn more about the network and have your say on how it can support the needs of Queer Artists across the North through an artist development programme and strategic projects.',
          ShortDescription:'A panel discussion and networking event hosted by Queer Arts North, a new LGBTQ arts network for the North.',
          DescriptionBoxed:'',        
          TicketDescription: 'FREE (Donations welcome)',
          TicketType: 'book',
          TicketLink: 'https://www.alphabettitheatre.co.uk',
          AgeRestrictions: 'https://www.eventbrite.co.uk/e/queer-arts-north-tickets-46611087016',
          ImgPath: ['/assets/images/image-queer-arts-north-sq.jpg'],
          ImgThumb: ['/assets/images/image-queer-arts-north-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        }, 	
    
        {
          Title: "THE QUEST FOR THE PERFECT SHIRT",
          SlugTitle: "the-quest-for-the-perfect-shirt",
          Location: 'Curious Festival Hub, 8-9 Central Arcade, Newcastle, NE1 5AF',
          StartDateTime: new Date("July 04, 2018 11:00:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 05, 2018 18:00:00 UTC+0100"),
          ApproxDuration: 'All day',
          Description: 'Come and join artist, tailor and cultural anthropologist Richard Bliss, in his Quest for the Perfect Shirt (questforperfectshirt.com). Two days of conversation, cakes, and couture ‘chemise’ construction in the former Tourist Information Centre, Central Arcade, Newcastle.',
          ShortDescription:'Come and join artist, tailor and cultural anthropologist Richard Bliss, in his Quest for the Perfect Shirt.',
          DescriptionBoxed:'',        
          TicketDescription: 'FREE (no booking required)',
          TicketType: '',
          TicketLink: '',
          AgeRestrictions: '',
          ImgPath: ['/assets/images/image-the-quest-for-the-perfect-shirt-sq.jpg'],
          ImgThumb: ['/assets/images/image-the-quest-for-the-perfect-shirt-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        }, 	
    
        {
          Title: "MY NIGHT WITH REG (By Kevin Elyot) - Matinee",
          SlugTitle: "my-night-with-reg-by-kevin-elyot-matinee",
          Location: 'Middlesbrough Theatre, Middlesbrough, TS5 6SA',
          StartDateTime: new Date("July 06, 2018 02:30:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 06, 2018 04:30:00 UTC+0100"),
          ApproxDuration: '2 hours',
          Description: 'Set in the 1980’s gay community, this fantastic play is an echoing tale of friendship, love and loss that is both comic and moving. We follow Guy and his friends, some old and some new, over three years, as we see the unpicking of a very lonely man and his secret crush for his friend John. The mysterious “Reg”, even though he is unseen, is ever present as the group experience high times and low times as they face an escalating AIDS crisis.',
          ShortDescription:'Set in the 1980’s gay community, this fantastic play is an echoing tale of friendship, love and loss that is both comic and moving.',
          DescriptionBoxed:'',        
          TicketDescription: '£15.50 | Concessions £12.50 (inc booking fee)',
          TicketType: 'book',
          TicketLink: 'https://www.middlesbroughtheatre.co.uk',
          AgeRestrictions: '15yrs +, 15-17yrs to be accompanied by an adult.',
          ImgPath: ['/assets/images/image-my-night-with-reg-by-kevin-elyot-matinee-sq.jpg'],
          ImgThumb: ['/assets/images/image-my-night-with-reg-by-kevin-elyot-matinee-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        }, 
    
        {
          Title: "ARC IS CURIOUS",
          SlugTitle: "arc-is-curious",
          Location: 'ARC, Stockton, TS18 1LL',
          StartDateTime: new Date("July 05, 2018 19:30:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 05, 2018 21:30:00 UTC+0100"),
          ApproxDuration: '2 hours',
          Description: 'An evening of live performance, dance, comedy and cabaret. Expect a colourful, energetic and varied night, exploring and celebrating LGBTQ culture. Featuring David Hoyle and work from leading LGBTQ artists from across the region and beyond.',
          ShortDescription:'An evening of live performance, dance, comedy and cabaret.',
          DescriptionBoxed:'',        
          TicketDescription: 'Pay What You Decide',
          TicketType: 'book',
          TicketLink: 'https://www.arconline.co.uk',
          AgeRestrictions: '16yrs +, 16-17yrs to be accompanied by an adult.',
          ImgPath: ['/assets/images/image-arc-is-curious-sq.jpg'],
          ImgThumb: ['/assets/images/image-arc-is-curious-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        }, 
      
        {
          Title: "WORDS BARE (by Mandy Barker)",
          SlugTitle: "words-bare-by-mandy-barker",
          Location: 'ARC Stockton, TS18 1LL',
          StartDateTime: new Date("July 05, 2018 18:30:00 UTC+0100"),
          StartDateTimeComment: 'Please see ARC Stockton Opening hours',
          EndDateTime: new Date("August 18, 2018 23:00:00 UTC+0100"),
          ApproxDuration: '',
          Description: 'Words Bare is a research-gathered exhibition by Mandy Barker. It showcases comments and experiences the Tees Valley LGBTQ community still face in society. The artwork puts these social challenges into the limelight, and questions why this views are still very present today. Exhibition runs until 18th August',
          ShortDescription:'Words Bare is a research-gathered exhibition by Mandy Barker. (Launch 5th July, 6:30pm)',
          DescriptionBoxed:'An LGBTQ print exhibition.',        
          TicketDescription: 'FREE (No booking required)',
          TicketType: 'book',
          TicketLink: 'https://www.eventbrite.co.uk/e/curious-vogue-ballfestival-party-tickets-34958791687',
          AgeRestrictions: 'Age Restriction 18yrs+',
          ImgPath: ['/assets/images/image-words-bare-by-mandy-barker-sq.jpg'],
          ImgThumb: ['/assets/images/image-words-bare-by-mandy-barker-sq.jpg'],
          TextColor: 'curious-cyan',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-cyan-bg'
        }, 
      
        {
          Title: "HOPE HUNT AND THE ASCENSION INTO LAZARUS (By Oona Doherty)",
          SlugTitle: "hope-hunt-and-the-ascension-into-lazarus",
          Location: 'Dance City, Newcastle, NE1 4BR',
          StartDateTime: new Date("July 06, 2018 19:30:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 06, 2018 20:30:00 UTC+0100"),
          ApproxDuration: '60 minutes',
          Description: 'This is thumping sweaty dance-theatre; a physical expression of the place between the flesh and the soul. Heaving lungs shout in the dark as Doherty takes you through a dirty Europe, from the dark and into the light like a bursting lightbulb. The show explores the experience and feelings of growing up white, male and disadvantaged.',
          ShortDescription:'This is thumping sweaty dance-theatre; a physical expression of the place between the flesh and the soul. ',
          DescriptionBoxed:'Strong language warning',        
          TicketDescription: '£13.50 | Concessions £8 & £11',
          TicketType: 'book',
          TicketLink: 'https://www.dancecity.co.uk',
          AgeRestrictions: 'All ages (strong language)',
          ImgPath: ['/assets/images/image-hope-hunt-and-the-ascension-into-lazarus-sq.jpg'],
          ImgThumb: ['/assets/images/image-hope-hunt-and-the-ascension-into-lazarus-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        }, 
  
        {
          Title: "CURIOUS SCRATCH NIGHT",
          SlugTitle: "curious-scratch-night",
          Location: 'Alphabetti Theatre, Newcastle, NE1 4HP',
          StartDateTime: new Date("July 06, 2018 21:00:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 06, 2018 23:00:00 UTC+0100"),
          ApproxDuration: '2 hours',
          Description: 'An evening of experimental queer performance. Showcasing works in the early stages of their development from Northern artists exploring and celebrating LGBTQ themes. Artists include Beth O’Doherty and recipients of the Curious 2018 ‘Queer Itch’ bursaries.',
          ShortDescription:'An evening of experimental queer performance.',
          DescriptionBoxed:'',        
          TicketDescription: '£6',
          TicketType: 'book',
          TicketLink: 'https://www.alphabettitheatre.co.uk',
          AgeRestrictions: '16yrs +, 16-17yrs to be accompanied by an adult',
          ImgPath: ['/assets/images/image-curious-scratch-night-sq.jpg'],
          ImgThumb: ['/assets/images/image-curious-scratch-night-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        }, 
      
        {
          Title: "CURIOUS ABOUT VISUAL ARTS",
          SlugTitle: "curious-about-visual-arts",
          Location: 'Curious Festival Hub, 8 - 9 Central Arcade Newcastle, NE1 5AF',
      
          StartDateTime: new Date("July 06, 2018 12:00:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 06, 2018 13:00:00 UTC+0100"),
          ApproxDuration: '60 minutes',
          Description: 'An open discussion and chance to meet some of the visual artists showcased in Curious Festival 2018.',
          ShortDescription:'An open discussion and chance to meet some of the visual artists showcased in Curious Festival 2018.',
          DescriptionBoxed:'BSL interpreted',        
          TicketDescription: 'FREE (no booking required)',
          TicketType: '',
          TicketLink: '',
          AgeRestrictions: 'All ages',
          ImgPath: ['/assets/images/image-curious-about-visual-arts-sq.jpg'],
          ImgThumb: ['/assets/images/image-curious-about-visual-arts-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        }, 
      
    
        {
          Title: "RAINBOW RAVE PRESENTED BY CHALK",
          SlugTitle: "rainbow-rave-presented-by-chalk",
          Location: 'Seven Stories, Newcastle, NE1 2PQ',
          StartDateTime: new Date("July 07, 2017 10:30:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 07, 2017 11:30:00 UTC+0100"),
          ApproxDuration: '60 minutes',
          Description: 'Express yourself and celebrate all families at this fabulous rainbow dance event! Join Chalk and Curious for this colourful LGBTQ+ celebratory rave. An opportunity to express your family’s identity with pride. The Seven Stories attic will become awash with colour as you get the chance to don rainbow face paint, bathe in bubbles and showcase your hula hooping skills to an eclectic mix of dance inducing tunes!',
          ShortDescription:'Express yourself and celebrate all families at this fabulous rainbow dance event!',
          DescriptionBoxed:'',        
          TicketDescription: '£5',
          TicketType: 'book',
          TicketLink: 'https://sevenstories.org.uk',
          AgeRestrictions: 'Under 5’s and families',
          ImgPath: ['/assets/images/image-rainbow-rave-presented-by-chalk-sq.jpg'],
          ImgThumb: ['/assets/images/image-rainbow-rave-presented-by-chalk-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        }, 
      
        {
          Title: "CURIOUS STUDIO TAKEOVER",
          SlugTitle: "curious-studio-takeover",
          Location: 'Seven Stories, Newcastle, NE1 2PQ',
          StartDateTime: new Date("July 07, 2018 13:00:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 07, 2018 15:00:00 UTC+0100"),
          ApproxDuration: '2 hours',
          Description: 'Join guest artists in a special studio takeover by Curious Arts. Make a statement in this creative session where you can make a piece of wearable art to take away!',
          ShortDescription:'Join guest artists in a special studio takeover by Curious Arts.',
          DescriptionBoxed:'',        
          TicketDescription: '£7 (includes all materials and an item to take away)',
          TicketType: 'book',
          TicketLink: 'https://www.sevenstories.org.uk',
          AgeRestrictions: '7yrs+ and families',
          ImgPath: ['/assets/images/image-curious-studio-takeover-sq.jpg'],
          ImgThumb: ['/assets/images/image-curious-studio-takeover-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        }, 
      
        {
          Title: "SPECTACULAR DRAG STORY TIME",
          SlugTitle: "spectacular-drag-story-time",
          Location: 'Seven Stories, Newcastle, NE1 2PQ',
          StartDateTime: new Date("July 08, 2017 14:00:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 08, 2017 14:45:00 UTC+0100"),
          ApproxDuration: '45 minutes',
          Description: 'Glitter-Wiggle yourself down to Seven Stories for this fabulous event and meet Northern drag artists Ben and Gladys who will share some of their favourite books! Dress up not down, it’s going to be fierce. Yaaaassssss queens &amp; kings!',
          ShortDescription:'Glitter-Wiggle yourself down to Seven Stories for this fabulous event and meet Northern drag artists.',
          DescriptionBoxed:'',        
          TicketDescription: '£3',
          TicketType: 'book',
          TicketLink: 'https://www.evenstories.org.uk',
          AgeRestrictions: '5yrs + and families',
          ImgPath: ['/assets/images/image-spectacular-drag-story-time-sq.jpg'],
          ImgThumb: ['/assets/images/image-spectacular-drag-story-time-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        }, 
          
  
        {
          Title: "VISIBLY DRAWN - CURIOUS OUTDOOR LIFE DRAWING",
          SlugTitle: "visibly-drawn-curious-outdoor-life-drawing",
          Location: 'Sage Gateshead, Gateshead Quays, NE8 2JR',
          StartDateTime: new Date("July 07, 2018 13:00:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 07, 2018 15:30:00 UTC+0100"),
          ApproxDuration: '90 minutes',
          Description: 'Your chance to draw a number of clothed models who will be posing to represent the diversity of LGBTQ bodies and fashion outside Sage Gateshead. The event is suitable for all abilities and drawing equipment will be provided – though you’re welcome to bring your own! ',
          ShortDescription:'Your chance to draw a number of clothed models who will be posing to represent the diversity of LGBTQ.',
          DescriptionBoxed:'',        
          TicketDescription: 'FREE (booking essential!)',
          TicketType: 'book',
          TicketLink: 'https://www.eventbrite.co.uk/e/visibly-drawn-curious-outdoor-life-drawing-tickets-45900661113',
          AgeRestrictions: 'All ages',
          ImgPath: ['/assets/images/image-visibly-drawn-curious-outdoor-life-drawing-sq.jpg'],
          ImgThumb: ['/assets/images/image-visibly-drawn-curious-outdoor-life-drawing-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        }, 
      
        {
          Title: "BECOMING SCHEHERAZADE (By Kamaal Hussain)",
          SlugTitle: "becoming-scheherazade-by-kamaal-hussain",
          Location: 'Alphabetti Theatre, Newcastle, NE1 4HP',
          StartDateTime: new Date("July 07, 2018 18:30:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 07, 2018 19:30:00 UTC+0100"),
          ApproxDuration: '60 minutes',
          Description: 'Magic and reality collide as one British Arab navigates the voyages of Sindbad the Sailor and tries to make sense of his own family’s migration to the UK. An Absorbing, moving, funny tale, full of unexpected twists and turns, Becoming Scheherazade uses The Arabian Nights; tales that spared Scheherazade from the King’s axe.',
          ShortDescription:'Becoming Scheherazade uses The Arabian Nights; tales that spared Scheherazade from the King’s axe.',
          DescriptionBoxed:'',        
          TicketDescription: '£6',
          TicketType: 'book',
          TicketLink: 'https://www.alphabettitheatre.co.uk',
          AgeRestrictions: '16yrs +, 16-17yrs to be accompanied by an adult.',
          ImgPath: ['/assets/images/image-becoming-scheherazade-by-kamaal-hussain-sq.jpg'],
          ImgThumb: ['/assets/images/image-becoming-scheherazade-by-kamaal-hussain-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        }, 

        {
          Title: "TEA LADIES",
          SlugTitle: "tea-ladies",
          Location: 'BALTIC Square, BALTIC Centre For Contemporary Art, Gateshead, NE8 3BA',
          StartDateTime: new Date("July 07, 2018 13:00:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 07, 2018 17:00:00 UTC+0100"),
          ApproxDuration: 'All afternoon',
          Description: 'Watch out for our Tea Ladies; Sisters Jean and Morag, ladies of a certain age, who will happily invade any public space on their touring tricycle. They won’t hesitate to get interactive with the public and all are invited to their impromptu tea parties. Tea is poured, gossip is dished and there’s always a sponge finger or two to be enjoyed.',
          ShortDescription:'Watch out for our Tea Ladies; Sisters Jean and Morag!',
          DescriptionBoxed:'Drop in',        
          TicketDescription: 'FREE (no booking required)',
          TicketType: '',
          TicketLink: '',
          AgeRestrictions: 'All ages',
          ImgPath: ['/assets/images/image-tea-ladies-sq.jpg'],
          ImgThumb: ['/assets/images/image-tea-ladies-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        }, 
  
        {
          Title: "MUM (By Anne-Marie O-Connor and Kate O’Donnell)",
          SlugTitle: "mum-by-anne-marie-oconnor-and-kate-odonnell",
          Location: 'Alphabetti Theatre, Newcastle, NE1 4HP',
          StartDateTime: new Date("July 07, 2018 19:15:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 07, 2018 19:25:00 UTC+0100"),
          ApproxDuration: '10 minutes',
          Description: 'A film about the love between a mother and daughter and the things that go unsaid in families. A transgender central character but a universal story. When Kate goes back to see her mum after time apart, she realises that far from being ready for a day out, her mum is gravely ill and nobody has told her.',
          ShortDescription:'A film about the love between a mother and daughter and the things that go unsaid in families.',
          DescriptionBoxed:'',        
          TicketDescription: 'Pay What You Decide',
          TicketType: 'book',
          TicketLink: 'https://www.alphabettitheatre.co.uk',
          AgeRestrictions: '14yrs+',
          ImgPath: ['/assets/images/image-mum-by-anne-marie-oconnor-and-kate-odonnell-sq.jpg'],
          ImgThumb: ['/assets/images/image-mum-by-anne-marie-oconnor-and-kate-odonnell-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        },   
    
        {
          Title: "CURIOUS VOGUE BALL",
          SlugTitle: "curious-vogue-ball",
          Location: 'Breeze Creatives, Bamburgh House, Newcastle, NE1 6BH',
          StartDateTime: new Date("July 07, 2018 20:00:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 07, 2018 23:59:00 UTC+0100"),
          ApproxDuration: '4 hours',
          Description: '<p>The annual Vogue Ball returns – Curious style, a party, live music, art and dance performances, the ultimate celebration of North East LGBTQ Culture hosted by House of Ghetto with host Mutha Tucka.</p><p>For categories, to register as a house/individually for a category and for further information please contact Curious Arts at festivalcurious@gmail.com or via our Facebook page.</p><p>Check out our Curious Voguing Workshops online and be part of a pop up Vogue House!</p>',
          ShortDescription:'The annual Vogue Ball returns – Curious style, a party, live music, art and dance performances, the ultimate celebration!',
          DescriptionBoxed:'*Category registration required.',        
          TicketDescription: '£7 (turn up, enjoy & watch), £8 (turn up, enjoy & watch up close), £6 Vogue Ball Walker (turn up solo/part of a house and WALK!)*, £9 (on the door)',
          TicketType: 'book',
          TicketLink: 'https://www.eventbrite.co.uk/e/curious-vogue-ball-festival-party-2018-tickets-46014622976',
          AgeRestrictions: 'Age Restriction 18yrs+',
          ImgPath: ['/assets/images/image-curious-vogue-ball-sq.jpg'],
          ImgThumb: ['/assets/images/image-curious-vogue-ball-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        }, 	  
      
        {
          Title: "BALTIC IS CURIOUS : SLAP AND TICKLE (By Liz Aggiss)",
          SlugTitle: "baltic-is-curious-slap-and-tickle-by-liz-aggiss",
          Location: 'BALTIC Centre for Contemporary Art, Gateshead, NE8 3BA',
          StartDateTime: new Date("July 08, 2017 19:00:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 08, 2017 20:00:00 UTC+0100"),
          ApproxDuration: '60 minutes',
          Description: 'A dark and ribald physical commentary on cultural society, forays and sexual taboos. Liz Aggiss is centre stage in this solo performance in an enthusiastically moving and disorientating display of contradictions and interpretations, on girls, women, mummies, mothers, pensioners and senior citizens. Decoding mythologies and old wives tales, this performance navigates itself into a feminist soup; it is push and pull, punishment and reward, slap and tickle. ',
          ShortDescription:'A dark and ribald physical commentary on cultural society, forays and sexual taboos.',
          DescriptionBoxed:'',        
          TicketDescription: '£12/£8 (concessions), £10 (early brid before 30th June) + Booking Fee',
          TicketType: 'book',
          TicketLink: 'https://www.eventbrite.co.uk/e/baltic-is-curious-tickets-45587769245',
          AgeRestrictions: '14+yrs (14-17yrs accompanied by an adult)',
          ImgPath: ['/assets/images/image-baltic-is-curious-slap-and-tickle-by-liz-aggiss-sq.jpg'],
          ImgThumb: ['/assets/images/image-baltic-is-curious-slap-and-tickle-by-liz-aggiss-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        }, 
  
        {
          Title: "FESTIVAL FINALE : BALTIC IS CURIOUS",
          SlugTitle: "festival-finale-baltic-is-curious",
          Location: 'BALTIC Centre for Contemporary Art, Gateshead, NE8 3BA',
          StartDateTime: new Date("July 08, 2017 15:00:00 UTC+0100"),
          StartDateTimeComment: '',
          EndDateTime: new Date("July 08, 2017 23:00:00 UTC+0100"),
          ApproxDuration: '3pm onwards',
          Description: '<p>Curious Festival 2018’s finale is a day of female-led queer arts, produced by Gem Andrews, taking place on Baltic Square and inside BALTIC Centre for Contemporary Art, Gateshead. It cluminates in a ticketed performance by Liz Aggiss (see SLAP AND TICKLE).</p><p>The festivities begin on Baltic Square at 11am when Lubaina Himid’s flag on BALTIC’s roof will be raised; join in with a mass flag waving by bringing your own flag and celebrate the differences that we have in common. You can also make any undying declarations in The Inflatable Church of Love. Head to Level 1 inside BALTIC from 3pm onwards for screenings and performances.</p>',
          ShortDescription:'Curious Festival 2018’s finale is a day of female-led queer arts.',
          DescriptionBoxed:'',        
          TicketDescription: 'All free (donations welcome)',
          TicketType: '',
          TicketLink: '',
          AgeRestrictions: 'All ages',
          ImgPath: ['/assets/images/image-festival-finale-baltic-is-curious-sq.jpg'],
          ImgThumb: ['/assets/images/image-festival-finale-baltic-is-curious-sq.jpg'],
          TextColor: 'curious-purple',
          BoxedColor: 'curious-pink-bg',
          BgColor: 'curious-purple-bg'
        }, 
  
    ];

    data.forEach((obj: Event) => {
      this.addEvent(obj);
      console.log(obj);
     });

     */

/*
      var data: Event[] = [
      {
        Title: "POKE IS CURIOUS AFTER PARTY",
        SlugTitle: "poke-is-curious-after-party",
        Location: 'Kommunity NCL',
        StartDateTime: new Date("July 08, 2017 21:30:00 UTC+0100"),
        StartDateTimeComment: '',
        EndDateTime: new Date("July 08, 2017 21:30:00 UTC+0100"),
        ApproxDuration: '4 hours and 30 minutes',
        Description: 'POKE is Newcastle gay scene’s best-kept secret. This brilliant independent LGBTQ club night is ready \
        to WERK, SLAY &amp; STRIKE APOSE ALL NIGHT with an outrageous mix of indie, pop, punk, hip-hop and \
        electro bangers. So let’s party.',
        ShortDescription:'This brilliant independent LGBTQ club night is ready \
        to WERK, SLAY & STRIKE APOSE ALL NIGHT.',
        DescriptionBoxed:'',        
        TicketDescription: '£7-10 book via curiousfestival.co.uk or Pay On The Door',
        TicketType: 'book',
        TicketLink: 'https://www.eventbrite.co.uk/e/curious-vogue-ballfestival-party-tickets-34958791687',
        AgeRestrictions: 'Age Restriction 18yrs+',
        ImgPath: ['/img/image-poke-afterparty-sq.jpg'],
        ImgThumb: ['/img/image-poke-afterparty-sq.jpg'],
        TextColor: 'curious-purple',
        BoxedColor: 'curious-pink-bg',
        BgColor: 'curious-purple-bg'
      }, 

      {
        Title: "WORKSHOP :: CURIOUS LIFE DRAWING",
        SlugTitle: "curious-life-drawing-1",
        Location: 'BALTIC',
        StartDateTime: new Date("July 08, 2017 14:15:00 UTC+0100"),
        StartDateTimeComment: '',
        EndDateTime: new Date("July 08, 2017 14:15:00 UTC+0100"),
        ApproxDuration: '1 hour and 15 minutes',
        Description: '(PLEASE NOTE: THE VENUE FOR THIS EVENT IS BALTIC CENTRE FOR CONTEMPORARY ARTS, \
        THERE WAS A VENUE CHANGE FOR THIS EVENT SINCE THE BROCHURE WENT TO PRINT) \
        <p>An intimate and informal exchange between life model and participants. We will explore physical \
        poses that celebrate self-expression, naturalism and our animal selves. Experimental drawing \
        techniques will be offered as part of the class, techniques which ask us to further reflect on our ways \
        of looking at and being with others.</p>\
        <p>Curated and facilitated by Nicola Singh.</p>\
        <p>Suitable for all abilities</p>',
        ShortDescription:'An intimate and informal exchange between life model and participants. We will explore physical \
        poses that celebrate self-expression, naturalism and our animal selves. ',
        DescriptionBoxed:'',        
        TicketDescription: '£7.50 (+ Booking Fee)',
        TicketType: 'book',
        TicketLink: 'https://www.eventbrite.co.uk/e/curious-life-drawing-tickets-35214301925',
        AgeRestrictions: 'Age Restriction 14yrs+ (14-17yrs to be accompanied by an adult)',
        ImgPath: ['/img/image-curious-life-drawing-sq.jpg'],
        ImgThumb: ['/img/image-curious-life-drawing-sq.jpg'],
        TextColor: 'curious-green',
        BoxedColor: 'curious-pink-bg',
        BgColor: 'curious-green-bg'
      }, 

      {
        Title: "WORKSHOP :: CURIOUS LIFE DRAWING",
        SlugTitle: "curious-life-drawing-2",
        Location: 'BALTIC',
        StartDateTime: new Date("July 08, 2017 16:00:00 UTC+0100"),
        StartDateTimeComment: '',
        EndDateTime: new Date("July 08, 2017 16:00:00 UTC+0100"),
        ApproxDuration: '1 hour and 15 minutes',
        Description: '(PLEASE NOTE: THE VENUE FOR THIS EVENT IS BALTIC CENTRE FOR CONTEMPORARY ARTS, \
        THERE WAS A VENUE CHANGE FOR THIS EVENT SINCE THE BROCHURE WENT TO PRINT) \
        <p>An intimate and informal exchange between life model and participants. We will explore physical \
        poses that celebrate self-expression, naturalism and our animal selves. Experimental drawing \
        techniques will be offered as part of the class, techniques which ask us to further reflect on our ways \
        of looking at and being with others.</p>\
        <p>Curated and facilitated by Nicola Singh.</p>\
        <p>Suitable for all abilities</p>',
        ShortDescription:'An intimate and informal exchange between life model and participants. We will explore physical \
        poses that celebrate self-expression, naturalism and our animal selves. ',
        DescriptionBoxed:'',        
        TicketDescription: '£7.50 (+ Booking Fee)',
        TicketType: 'book',
        TicketLink: 'https://www.eventbrite.co.uk/e/curious-life-drawing-tickets-35215609837',
        AgeRestrictions: 'Age Restriction 14yrs+ (14-17yrs to be accompanied by an adult)',
        ImgPath: ['/img/image-curious-life-drawing-sq.jpg'],
        ImgThumb: ['/img/image-curious-life-drawing-sq.jpg'],
        TextColor: 'curious-green',
        BoxedColor: 'curious-pink-bg',
        BgColor: 'curious-green-bg'
      }, 

      {
        Title: "WORKSHOP :: QUEER EXPRESSION MAKEUP MASTERCLASS",
        SlugTitle: "queer-expression-makeup-masterclass",
        Location: 'Breeze Creatives',
        StartDateTime: new Date("July 08, 2017 14:00:00 UTC+0100"),
        StartDateTimeComment: '',
        EndDateTime: new Date("July 08, 2017 14:00:00 UTC+0100"),
        ApproxDuration: '1 hour and 15 minutes',
        Description: 'A masterclass in exploring and expressing gender and identity through makeup. To encourage \
        confidence and self-expression Grace Oni Smith will be sharing tips and techniques gained over a \
        decade of experience in fashion, modelling and queer/drag/live art performance.',
        ShortDescription:'A masterclass in exploring and expressing gender and identity through makeup. To encourage \
        confidence and self-expression. ',
        DescriptionBoxed:'',        
        TicketDescription: '£7.50 (+ Booking Fee)',
        TicketType: 'book',
        TicketLink: 'https://www.eventbrite.co.uk/e/queer-expression-makeup-masterclass-tickets-35266407775',
        AgeRestrictions: 'Age Restriction 14yrs+ (14-17yrs to be accompanied by an adult)',
        ImgPath: ['/img/image-queer-expression-sq.jpg'],
        ImgThumb: ['/img/image-queer-expression-sq.jpg'],
        TextColor: 'curious-green',
        BoxedColor: 'curious-pink-bg',
        BgColor: 'curious-green-bg'
      }, 

      {
        Title: "WORKSHOP :: CURIOUS VOGUING WORKSHOPS AND PERFORMANCE (PART 1)",
        SlugTitle: "curious-voguing-workshops-and-performance-1",
        Location: 'Space Six',
        StartDateTime: new Date("June 30, 2017 18:00:00 UTC+0100"),
        StartDateTimeComment: '',
        EndDateTime: new Date("June 30, 2017 18:00:00 UTC+0100"),
        ApproxDuration: '2 hours',
        Description: 'Join Dancer/Choreographers Patrick Ziza &amp; Lerato Mncube for two workshops for your chance to \
        strike a pose, learn about voguing and create a Pop Up group Vogue performance for the Curious \
        Vogue Ball: Festival Party.',
        ShortDescription:'Two workshops for your chance to \
        strike a pose, learn about voguing and create a Pop Up group Vogue performance.',
        DescriptionBoxed:'',        
        TicketDescription: '£18 (+ Booking Fee) Ticket Price includes both workshops and entry to the Vogue Ball for the \
        performance on Saturday 8 July at 8pm',
        TicketType: 'book',
        TicketLink: 'https://www.eventbrite.co.uk/e/curious-voguing-workshops-and-performance-tickets-35218148430',
        AgeRestrictions: 'Age Restriction 16yrs+',
        ImgPath: ['/img/image-curious-voguing-sq.jpg'],
        ImgThumb: ['/img/image-curious-voguing-sq.jpg'],
        TextColor: 'curious-green',
        BoxedColor: 'curious-pink-bg',
        BgColor: 'curious-green-bg'
      }, 
       {
        Title: "WORKSHOP :: CURIOUS VOGUING WORKSHOPS AND PERFORMANCE (PART 2)",
        SlugTitle: "curious-voguing-workshops-and-performance-2",
        Location: 'Space Six',
        StartDateTime: new Date("July 08, 2017 14:00:00 UTC+0100"),
        StartDateTimeComment: '',
        EndDateTime: new Date("July 08, 2017 14:00:00 UTC+0100"),
        ApproxDuration: '2 hours',
        Description: 'Join Dancer/Choreographers Patrick Ziza &amp; Lerato Mncube for two workshops for your chance to \
        strike a pose, learn about voguing and create a Pop Up group Vogue performance for the Curious \
        Vogue Ball: Festival Party.',
        ShortDescription:'Two workshops for your chance to \
        strike a pose, learn about voguing and create a Pop Up group Vogue performance.',
        DescriptionBoxed:'',        
        TicketDescription: '£18 (+ Booking Fee) Ticket Price includes both workshops and entry to the Vogue Ball for the \
        performance on Saturday 8 July at 8pm',
        TicketType: 'book',
        TicketLink: 'https://www.eventbrite.co.uk/e/curious-voguing-workshops-and-performance-tickets-35218148430',
        AgeRestrictions: 'Age Restriction 16yrs+',
        ImgPath: ['/img/image-curious-voguing-sq.jpg'],
        ImgThumb: ['/img/image-curious-voguing-sq.jpg'],
        TextColor: 'curious-green',
        BoxedColor: 'curious-pink-bg',
        BgColor: 'curious-green-bg'
      }, 

       {
        Title: "CURIOUS VOGUING WORKSHOPS AND PERFORMANCE",
        SlugTitle: "curious-voguing-workshops-and-performance-3",
        Location: 'Breeze Creatives',
        StartDateTime: new Date("July 08, 2017 20:00:00 UTC+0100"),
        StartDateTimeComment: '',
        EndDateTime: new Date("July 08, 2017 20:00:00 UTC+0100"),
        ApproxDuration: '2 hours and 30 minutes',
        Description: 'Join Dancer/Choreographers Patrick Ziza &amp; Lerato Mncube for two workshops for your chance to \
        strike a pose, learn about voguing and create a Pop Up group Vogue performance for the Curious \
        Vogue Ball: Festival Party.',
        ShortDescription:'Two workshops for your chance to \
        strike a pose, learn about voguing and create a Pop Up group Vogue performance.',
        DescriptionBoxed:'',        
        TicketDescription: '£18 (+ Booking Fee) Ticket Price includes both workshops and entry to the Vogue Ball for the \
        performance on Saturday 8 July at 8pm',
        TicketType: 'book',
        TicketLink: 'https://www.eventbrite.co.uk/e/curious-voguing-workshops-and-performance-tickets-35218148430',
        AgeRestrictions: 'Age Restriction 16yrs+',
        ImgPath: ['/img/image-curious-voguing-sq.jpg'],
        ImgThumb: ['/img/image-curious-voguing-sq.jpg'],
        TextColor: 'curious-green',
        BoxedColor: 'curious-pink-bg',
        BgColor: 'curious-green-bg'
      }, 
      {
        Title: "WORDS BARE",
        SlugTitle: "exhibition-words-bare",
        Location: 'Gillian Dickinson Gallery, Northern Stage',
        StartDateTime: new Date("July 5, 2017 00:00:00 UTC+0100"),
        StartDateTimeComment: 'Please see Northern Stage Opening Hours',
        EndDateTime: new Date("Jul 26, 2017 00:00:00 UTC+0100"),
        ApproxDuration: '50 minutes',
        Description: 'Words Bare is an LGBTQ print exhibition which showcases research-gathered comments \
        and experiences the community still face in society today. The posters put LGBTQ \
        social challenges into the limelight, and questions why these views are still \
        very present today.',
        ShortDescription:'Words Bare is an LGBTQ print exhibition which showcases research-gathered comments \
        and experiences the community still face in society today.',
        DescriptionBoxed:'',        
        TicketDescription: 'FREE No booking required',
        TicketType: 'book',
        TicketLink: '',
        AgeRestrictions: '',
        ImgPath: ['/img/words-bare.jpg'],
        ImgThumb: ['/img/words-bare-sq.jpg'],
        TextColor: 'curious-cyan',
        BoxedColor: 'curious-pink-bg',
        BgColor: 'curious-cyan-bg'
      }, {
        Title: "Northern Stage is curious",
        SlugTitle: "northern-stage-is-curious",
        Location: 'Stage 3, Northern Stage',
        StartDateTime: new Date("Jul 5, 2017 19:00:00 UTC+0100"),
        StartDateTimeComment: '',
        EndDateTime: new Date("Jul 5, 2017 19:00:00 UTC+0100"),
        ApproxDuration: 'approximately 1hr 30 minutes',
        Description: 'Join us for the curious 2017 launch evening at Northern Stage. \
        Showcasing new performance and writing from across the North. \
        The evening features an extract of “Shorty” by Hester Chillingworth, a work in progress sharing of \
        Petrichor a new work in development by ZENDEH and a celebration showcase of New Writing North’s \
        Proud Words LGBT+ reading and creative writing project for young people. Please note the \
        performance will be followed by a short post show discussion.',
        ShortDescription:'An extract of “Shorty” by Hester Chillingworth, a work in progress sharing of \
        Petrichor a new work in development by ZENDEH and a celebration showcase of New Writing North’s \
        Proud Words.',
        DescriptionBoxed:'',
        TicketDescription: 'Tickets £7 / Concessions £5 (students, 60+ and unwaged)',
        TicketType: 'buy',
        TicketLink: 'https://www.northernstage.co.uk/whats-on/northern-stage-is-curious',
        AgeRestrictions: 'Age Restriction 14yrs+',
        ImgPath: ['/img/image-northern-stage-is-curious-sq.jpg'],
        ImgThumb: ['/img/image-northern-stage-is-curious-sq.jpg'],
        TextColor: 'curious-magenta',
        BoxedColor: 'curious-pink-bg',
        BgColor: 'curious-magenta-bg'
      }, {
        Title: "Mothers Ruin is curious",
        SlugTitle: "mothers-ruin-is-curious",
        Location: 'ARC Stockton',
        StartDateTime: new Date("July 6, 2017 20:00:00 UTC+0100"),
        StartDateTimeComment: '',
        EndDateTime: new Date("July 6, 2017 20:00:00 UTC+0100"),
        ApproxDuration: 'approximately 120 mins including interval',
        Description: 'A sensational, cabaret-style alternative variety night celebrating the cream of emerging and \
        established queer northern talent. Hosted by your glamorous eco-warrior hirsute hostess \
        Timberlina, and featuring from Manchester, the dead-pan funny Fat Roland; Newcastle&#39;s Pam Ayres&#39; \
        on Prozac, Ginger Johnson; from Leeds the delicious anti-diva Charlotte Victoria Furness; and the \
        raw physicality of a world dance piece entitled &#39;A Blighted Life&#39; by Gavin Coward performed by \
        Patrick Ziza, Alicia Meehan &amp; Gavin Coward, as well as performances from emerging local LGBTQ \
        artists.',
        ShortDescription:'A sensational, cabaret-style alternative variety night celebrating the cream of emerging and \
        established queer northern talent. ',
        DescriptionBoxed:'',
        TicketDescription: 'Tickets PAY WHAT YOU DECIDE (Booking in advance recommended)',
        TicketType: 'buy',
        TicketLink: 'http://arconline.co.uk/whats-on/theatre-dance/mothers-ruin-is-curious',
        AgeRestrictions: 'Age Restriction 14yrs+ Only, 14-17yrs to be accompanied by an adult',
        ImgPath: ['/img/image-mothers-ruin-sq.jpg'],
        ImgThumb: ['/img/image-mothers-ruin-sq.jpg'],
        TextColor: 'curious-magenta',
        BoxedColor: 'curious-pink-bg',
        BgColor: 'curious-magenta-bg'
      }, 
      
      {
        Title: "Theseus Beefcake",
        SlugTitle: "theseus-beefcake",
        Location: 'Dance City',
        StartDateTime: new Date("July 7, 2017 19:30:00 UTC+0100"),
        StartDateTimeComment: '',
        EndDateTime: new Date("July 7, 2017 19:30:00 UTC+0100"),
        ApproxDuration: 'approximately 60 mins',
        Description: 'A hero and minotaur are locked in the ultimate death match. \
        Theseus Beefcake journeys into the dark labyrinth of masculinity, colliding mixed martial arts, \
        death metal, American frat culture, rodeos, pornography and Greek mythology along the way. \
        Joseph Mercier and Jordan Lennie push against their personal thresholds of pain and pleasure \
        in this unforgettable duet exploring masculine excess, fantasy, friendship and competition. \
        Free Pre-Show Talk, 6.45pm Join us for a pre-show talk on Friday with co-Artistic Directors \
        of PanicLab, Joseph Mercier and Jordan Lennie, along with Dance City\'s Artistic Director, \
        Anthony Baker. Joseph and Jordan will discuss the themes and ideas behind Theseus Beefcake. \
        Places are free but booking is required. Free Post Show Talk A free post-show discussion will \
        take place immediately after the performance with the company which all ticket holders are \
        welcome to stay for. No booking required.',
        ShortDescription:'Colliding mixed martial arts, \
        death metal, American frat culture, rodeos, pornography and Greek mythology along the way.',
        DescriptionBoxed:'',
        TicketDescription: 'Tickets £12.50 / £7.50 students & under 18s / £9.50 concession',
        TicketType: 'buy',
        TicketLink: 'https://www.dancecity.co.uk/performance/62296/theseus-beefcake-paniclab/',
        AgeRestrictions: 'Age Restriction 14yrs+',
        ImgPath: ['/img/TheseusBeefcake.jpg'],
        ImgThumb: ['/img/TheseusBeefcake-sq.jpg'],
        TextColor: 'curious-magenta',
        BoxedColor: 'curious-pink-bg',
        BgColor: 'curious-magenta-bg'
      }, {
        Title: "TALK :: Queer Space, Time To Talk",
        SlugTitle: "talk-queer-space-time-to-talk",
        Location: 'Stage 3 Northern Stage',
        StartDateTime: new Date("July 8, 2017 11:30:00 UTC+0100"),
        StartDateTimeComment: '',
        EndDateTime: new Date("July 8, 2017 11:30:00 UTC+0100"),
        ApproxDuration: '90 mins',
        Description: 'Queer Space Time To Talk is an Artist led interactive discussion. \
        A rare opportunity to talk about contemporary queer arts and culture. The interactive \
        panel discussion will feature artists from the curious festival programme. They will \
        share their thoughts and examples of their work. curious festival’s executive producer \
        Phil Douglas will launch the call out for the curious 2017 commission in partnership \
        with Northern Stage.',
        ShortDescription:'Queer Space Time To Talk is an Artist led interactive discussion. \
        A rare opportunity to talk about contemporary queer arts and culture.',
        DescriptionBoxed:'',
        TicketDescription: 'FREE (Booking required)',
        TicketType: 'book',
        TicketLink: 'https://www.northernstage.co.uk/whats-on/queer-space',
        AgeRestrictions: 'Age Restriction 14yrs+ Only, 14-17yrs to be accompanied by an adult',
        ImgPath: ['/img/image-queer-space-sq.jpg'],
        ImgThumb: ['/img/image-queer-space-sq.jpg'],
        TextColor: 'curious-red',
        BoxedColor: 'curious-pink-bg',
        BgColor: 'curious-red-bg'
      }, {
        Title: "BALTIC Live is curious",
        SlugTitle: "baltic-live-is-curious",
        Location: 'BALTIC',
        StartDateTime: new Date("July 9, 2017 19:00:00 UTC+0100"),
        StartDateTimeComment: '',
        EndDateTime: new Date("July 9, 2017 19:00:00 UTC+0100"),
        ApproxDuration: '',
        Description: 'The finale of curious festival 2017 is an evening showcase of some of the best \
        Queer Live Art performance. Including Hayley and Me by award-winning trans performer/writer \
        Kate O’Donnell; 2016 curious festival commission A Blighted Life by Gavin Coward and a \
        performance by the evening’s host, sensational anti-drag queen David Hoyle.',
        ShortDescription:'The finale of curious festival 2017 is an evening showcase of some of the best \
        Queer Live Art performance. ',
        DescriptionBoxed:'',        
        TicketDescription: 'Tickets £12 / £8 concessions* / £10 before 30 June (+ Booking Fee)',
        TicketType: 'buy',
        TicketLink: 'https://www.eventbrite.co.uk/e/baltic-live-is-curious-tickets-34594312520?aff=eprofsaved',
        AgeRestrictions: ' 14yrs+ 14-17yrs to be accompanied by an adult',
        ImgPath: ['/img/image-baltic-live-is-curious-sq.jpg','/img/image-baltic-live-is-curious-2-sq.jpg'],
        ImgThumb: ['/img/image-baltic-live-is-curious-sq.jpg','/img/image-baltic-live-is-curious-2-sq.jpg'],
        TextColor: 'curious-magenta',
        BoxedColor: 'curious-pink',
        BgColor: 'curious-magenta-bg'
      }, {
        Title: "TALK :: North East Trans History - Time To Talk",
        SlugTitle: "talk-north-east-trans-history-time-to-talk",
        Location: 'BALTIC',
        StartDateTime: new Date("July 9, 2017 15:00:00 UTC+0100"),
        StartDateTimeComment: '',
        EndDateTime: new Date("July 9, 2017 15:00:00 UTC+0100"),
        ApproxDuration: '75 mins',
        Description: 'Transgender History North East - Open discussion. A rare opportunity to talk about the Transgender \
        social History of the region. queer arts and culture. How can we use the arts to raise visibility of our history? \
        Speakers and session facilitators include Kate’ O Donnel, Tara Stone and Emma RoeBuck.',
        ShortDescription:'Open discussion. A rare opportunity to talk about the Transgender \
        social History of the region. queer arts and culture.',
        DescriptionBoxed:'',
        TicketDescription: ' Tickets are FREE (booking required - booking fee applies)',
        TicketType: 'book',
        TicketLink: 'https://www.eventbrite.co.uk/e/curious-festival-talk-trans-history-north-east-tickets-35217327976',
        AgeRestrictions: ' 14yrs+ 14-17yrs to be accompanied by an adult',
        ImgPath: ['img/image-trans-talk-sq.jpg'],
        ImgThumb: ['img/image-trans-talk-sq.jpg'],
        TextColor: 'curious-red',
        BoxedColor: 'curious-pink-bg',
        BgColor: 'curious-red-bg'
      }, {
        Title: "Curious Vogue Ball:Festival Party",
        SlugTitle: "curious-vogue-ball-festival-party",
        Location: 'Breeze Creatives',
        StartDateTime: new Date("July 8, 2017 19:30:00 UTC+0100"),
        StartDateTimeComment: '',
        EndDateTime: new Date("July 8, 2017 19:30:00 UTC+0100"),
        ApproxDuration: 'approx 2 hour 30 mins',
        Description: '			A Vogue Ball like no other - curious festival style, a party, live music, \
        art and dance performances the ultimate celebration of LGBTQ Culture with guest performances \
        from Grace the Face Smith, Women In Revolt & more TBC.<br /><br /> \
        Categories & details for ball walkers \
        <ul> \
        <li> ICONIC Queer / Pop  ( celebrate your icon!)</li> \
        <li> Toon Vogue ( strike a pose)</li> \
        <li> Hair from here to the Wear ( Colour, size and style bring it!)</li> \
        <li> The Gender Agenda.... Realness (Be You Truly, Loudly)</li> \
        <li> FASHION FWD : (Best dressed)</li> \
        <li> TBC </li> \
        </ul> \
        To register as a house or individually for a category message curious Facebook page or email festivalcurious@gmail.com<br /> \
        Everyone : Dress Up Not Down - Life is for Living<br /> \
        Host: Mutha Tucka (Alexander Robinson) DJ Ian Harrington (POKE)<br />',
        ShortDescription:'A Vogue Ball like no other - curious festival style, a party, live music, \
        art and dance performances.',
        DescriptionBoxed: 'Don\'t Forget the Poke Newcastle after party at Kommunity!<br /> \
        Described by The Crack magazine as "one of the best club nights in the North East", POKE is \
        undoubtedly one of Newcastle gay scene\'s best-kept secrets. A brilliant independent LGBTQ \
        club night where the quality of the music is the number one focus. It\'s POKE\'s unparelleled \
        mix of indie, hip-hop, electro-dance, punk and pop, combined with one of the friendliest \
        atmospheres around, that has kept people coming back time and again since its inception \
        back in 2009.</br > \
        Facebook/Twitter/Instagram @pokenewcastle<br /> \
        9.30pm - 2am<br /> \
        £7 on the door (or see our combi ticket offer)<br />',
        TicketDescription: 'Tickets :<br /> \
        £7 Vogue Ball (Turn up, enjoy & watch)<br /> \
        £8 Vogue Ball ( Turn up, enjoy & Watch close up)<br /> \
        £10 Vogue Ball + POKE afterparty ( Turn up, enjoy, watch & party)<br /> \
        £6 Vogue Ball Walker (Turn up solo or as part of a house and WALK! registration for category also required)<br />',
        TicketType: 'book',
        TicketLink: 'https://www.eventbrite.co.uk/e/curious-vogue-ballfestival-party-tickets-34958791687',
        AgeRestrictions: ' 14yrs+ 14-17yrs to be accompanied by an adult',
        ImgPath: ['/img/image-vogue-ball-sq.jpg'],
        ImgThumb: ['/img/image-vogue-ball-sq.jpg'],
        TextColor: 'curious-magenta',
        BoxedColor: 'curious-pink-bg',
        BgColor: 'curious-magenta-bg'
      }
      
      
      ];
    

      data.forEach((obj: Event) => {
       this.addEvent(obj);
      });
      */


  }

  addEvent(event: Event){
    this.saveState="busy";
    var addDoc = this.afs.collection('events').add(event).then(ref => {
      console.log('Added document with ID: ', ref.id);

    //  this.eventDocRef.update(ref).then(e => {
   //     console.log(event);
    //    this.saveState="done";
    //  });
  

      this.saveState="done";
    });

  }

  setEvent(event: Event){
    this.saveState="busy";
    this.eventDocRef = this.afs.doc('events/'+event.id);
    this.eventDocRef.update(event).then(e => {
      console.log(event);
      this.saveState="done";
      }
    )
  }

  openEvent(ev){
    this.itsOpened[ev]="block";
    this.dropVeil="showVeil";
  //  TMgotoClass("container",0.5);
  }
  closeEvent(){
    this.itsOpened=new Array();
    this.dropVeil="hideVeil";
  }  

  closeNewsletter(){
    this.newsletterOpened='none';
  }

  openNewsletter(){
    this.newsletterOpened='block';
    window.scrollTo(0, 0);
  }

}
