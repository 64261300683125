import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Page } from '../../../models/page.model';
import { Observable } from 'rxjs/Observable';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.css']
})
export class AdminPageComponent implements OnInit {

  saveState:string = "done";
  dropVeil : string;
  itsOpened = new Array();
  currentYear: number;
  cKEditorContent: string = "text";

  ckeConfig: any;

  //
  pagesRef:AngularFirestoreCollection<Page>;
  pageDocRef:AngularFirestoreDocument<Page>;
  page$:Observable<Page[]>;

  constructor(private afs:AngularFirestore,  private route: ActivatedRoute ){
    this.dropVeil = "hideVeil";
    const settings = {timestampsInSnapshots: true};
    afs.firestore.settings(settings);

    // content..

    this.route.params.subscribe( 
      params => {
        this.pagesRef = this.afs.collection('pages',ref => ref.where('titleSlug',"==",params.page));
        this.page$ = this.pagesRef.valueChanges();
        } 
      );

  }

 save(page:Page){
  this.saveState="busy";
  this.pageDocRef = this.afs.doc('pages/'+page.id);
  this.pageDocRef.update(page).then(e => {
    this.saveState="done";
    }
  )
  
}

  ngOnInit() {

      this.ckeConfig = {
          height: 400,
          language: "en",
          allowedContent: true,
          contentsCss: '/assets/editor_styles.css',

            

          toolbar: [
              { name: "editing", items: ["Find", "Replace", "SelectAll"] },
              { name: "clipboard", items: ["Cut", "Copy", "Paste", "PasteText", "PasteFromWord", "-", "Undo", "Redo"] },
              { name: "links", items: ["Link", "Unlink", "Anchor"] },
              { name: "tools", items: ["Maximize", "ShowBlocks"] },
              { name: "insert", items: ["Table", "HorizontalRule", "SpecialChar", "Iframe", "imageExplorer"] },
              { name: "document", items: ["Source"] },
              "/",
              { name: "basicstyles", items: ["Bold", "Italic", "Underline", "Strike", "Subscript", "Superscript", "-", "RemoveFormat"] },
              { name: "paragraph", items: ["NumberedList", "BulletedList"] },
              { name: "justify", items: ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"] },
              { name: "styles", items: ["Styles", "Format", "FontSize", "-", "TextColor", "BGColor"] }
          ]
      };

  }

}
