import { Component } from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from "@angular/router";
import {AuthGuard} from './_guards/auth.guard';

import { Observable } from 'rxjs/Observable';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Curious';
  loggedin: boolean = false;
  current: any;
  mobileNav: string; 
  user: Observable<firebase.User>;
  userID: string;
  errorMsg:string;
  email:string;
  password:string;
  showLogin:boolean = false;
  editState:string = "Edit";

  constructor(public activatedRoute: ActivatedRoute, private router: Router, private auth: AuthGuard, public afAuth: AngularFireAuth){
    this.current = "/";
    this.userID=null;
    this.afAuth.authState.subscribe((user: firebase.User) => {
            if(user){
              this.userID = user.uid;
              this.loadObservableElements();
            }
        });     
  }

  ngOnInit(): void {
    this.mobileNav = "mobile-nav-off";
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.current = val.url;
      }
    });

}


loadObservableElements():void{
  if(this.userID){
    this.loggedin=true;
    this.showLogin=false;
    this.auth.setLoggedIn();
  }else{
    this.auth.setLoggedOut();
  }
}


openMobileNav(){
  if(this.mobileNav){
  this.mobileNav = "";
  }else{
  this.mobileNav = "mobile-nav-off";
  }
}
closeMobileNav(){
  this.mobileNav = "mobile-nav-off";
}


login(){
  // show login window, don't change the state...
  //this.loggedin = this.auth.isLoggedIn();  

  this.showLogin=true;
}

logout(){
  this.afAuth.auth.signOut();
  this.auth.setLoggedOut();
  this.loggedin = this.auth.isLoggedIn();  
  this.router.navigateByUrl('/');
  this.userID=null;
}

edit(){
  // ignore if we're already on the editor...
  if(this.current.includes('/admin')) return false;

  if(this.current=='/'){
    this.router.navigateByUrl('/admin/edit-home');
    return false;
  }

  // edit the current page...
  this.router.navigateByUrl('/admin/edit-'+this.current.substr(1,this.current.length));
}

}
