import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from "@angular/router";
import {AuthGuard} from '../../_guards/auth.guard';

import { Observable } from 'rxjs/Observable';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {

  saveState:string = "done";
  dropVeil : string;
  itsOpened = new Array();
  currentYear: number;

  user: Observable<firebase.User>;
  userID: string;
  errorMsg:string;
  email:string;
  password:string;
  showLogin:boolean = true;
  loggedin: boolean = false;
  current: any;

  constructor(public activatedRoute: ActivatedRoute, private router: Router, private auth: AuthGuard, public afAuth: AngularFireAuth){
    this.current = "/";
    this.userID=null;
    this.afAuth.authState.subscribe((user: firebase.User) => {
            if(user){
              this.userID = user.uid;
              this.loadObservableElements();
            }
        });     
  }


  ngOnInit() {
  }

  loadObservableElements():void{
    if(this.userID){
      this.loggedin=true;
      this.showLogin=false;
      this.auth.setLoggedIn();
    }else{
      this.auth.setLoggedOut();
    }
  }
  signInUser(){
    this.errorMsg = "";
  
    if((!this.email || !this.password))
      this.errorMsg = "Please complete both email and password.";
  
    if(this.email && this.password)
    this.afAuth.auth
      .signInWithEmailAndPassword(this.email,this.password)
      .then(resolve => {
                this.errorMsg = "";
                this.router.navigateByUrl('/');
            }, reject => {
                this.errorMsg = reject.message;
            });
  }
}
