import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Page } from '../../models/page.model';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-qan',
  templateUrl: './qan.component.html',
  styleUrls: ['./qan.component.css']
})
export class QanComponent implements OnInit {

  pagesRef:AngularFirestoreCollection<Page>;
  page$:Observable<Page[]>;

  constructor(private afs:AngularFirestore) {

    const settings = {timestampsInSnapshots: true};
    afs.firestore.settings(settings);

    // content..
    this.pagesRef = this.afs.collection('pages',ref => ref.where('title',"==","Queer Arts North"));
    this.page$ = this.pagesRef.valueChanges();

   }
  
  ngOnInit() {
  }

}
