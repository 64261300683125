import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireStorage, AngularFireUploadTask } from 'angularfire2/storage';
import { Event } from '../../../models/event.model';
import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators';
import { firestore } from 'firebase';

@Component({
  selector: 'app-events',
  templateUrl: './admin-events.component.html',
  styleUrls: ['./admin-events.component.css']
})
export class AdminEventsComponent implements OnInit {

  eventsRef:AngularFirestoreCollection<Event>;
  event$:Observable<Event[]>;
  eventDocRef:AngularFirestoreDocument<Event>;
  saveState:string = "done";
  editingEvent:string = "";
  addingEvent:boolean = false;
  newR:Event;
  deleteR:string;
  copyR:Event;
  showCustom:boolean = false;
  cr:any;

  ckeConfig: any;

  // upload vars...
  task: AngularFireUploadTask;
  ref:any;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: Observable<string>;
  isHovering: boolean;
  uploadStatus:string;
  uploadObject:any;  

  constructor(private afs:AngularFirestore, private storage: AngularFireStorage){
    this.eventsRef = this.afs.collection('events',ref => ref.orderBy('StartDateTime','asc'));
    this.event$ = this.eventsRef.valueChanges();
    this.cleanEvent();
    this.uploadStatus = "";
  }

  ngOnInit() {

    this.ckeConfig = {
      height: 200,
      language: "en",
      allowedContent: true,
      contentsCss: '/assets/editor_styles.css',

        

      toolbar: [
          { name: "editing", items: ["Find", "Replace", "SelectAll"] },
          { name: "clipboard", items: ["Cut", "Copy", "Paste", "PasteText", "PasteFromWord", "-", "Undo", "Redo"] },
          { name: "links", items: ["Link", "Unlink", "Anchor"] },
          { name: "tools", items: ["Maximize", "ShowBlocks"] },
          { name: "insert", items: ["Table", "HorizontalRule", "SpecialChar", "Iframe", "imageExplorer"] },
          { name: "document", items: ["Source"] },
          "/",
          { name: "basicstyles", items: ["Bold", "Italic", "Underline", "Strike", "Subscript", "Superscript", "-", "RemoveFormat"] },
          { name: "paragraph", items: ["NumberedList", "BulletedList"] },
          { name: "justify", items: ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"] },
          { name: "styles", items: ["Styles", "Format", "FontSize", "-", "TextColor", "BGColor"] }
      ]
  };

  }

  // ==================================================================
  // new events...

  cleanEvent(){

    this.newR = {  id: '', 
    Title: '', 
    SlugTitle:'', 
    Location:'',
    StartDateTime: new firestore.Timestamp(new Date().getTime() /1000,0),
    StartDateTimeComment:'',
    StartDateDay: '',
    StartDateDayTime: '',
    EndDateTime: new firestore.Timestamp(new Date().getTime() /1000,0),
    EndDateDay:'',
    EndDateDayTime:'',
    Description: '', 
    ShortDescription: '',
    DescriptionBoxed: '',
    TicketDescription:	'',
    TicketType:	'', 
    TicketLink:	'',
    AgeRestrictions:	'',
    ApproxDuration:	'',
    ImgPath: ["/assets/images/curious-no-image.jpg"],
    ImgThumb: ["/assets/images/curious-no-image.jpg"],
    TextColor: 'curious-purple',
    BoxedColor: 'curious-pink-bg',
    BgColor: 'curious-purple-bg',
    pubDate: new Date(),
    pubBy: ''};

  }


  addEvent(){
    this.addingEvent=true;
  }

  setNewEvent(){
    this.saveState="busy";
    this.newR.id = this.afs.createId();

    var newStamp = new Date(this.newR.StartDateDay +"T"+this.newR.StartDateDayTime).getTime() /1000;
    this.newR.StartDateTime = new firestore.Timestamp(newStamp,0);

    // copy start date/time if necessary...
    if(this.newR.EndDateDay=="")this.newR.EndDateDay=this.newR.StartDateDay;
    if(this.newR.EndDateDayTime=="")this.newR.EndDateDayTime=this.newR.StartDateDayTime;

    var newEndStamp = new Date(this.newR.EndDateDay +"T"+this.newR.EndDateDayTime).getTime() /1000;
    this.newR.EndDateTime = new firestore.Timestamp(newEndStamp,0);

    this.eventDocRef = this.afs.doc('events/'+this.newR.id);
    this.eventDocRef.set(this.newR).then(e => {
      this.saveState="done";
      this.editingEvent="";
      }
    )    
    // once we're saved...
    this.addingEvent=false;
    this.cleanEvent();
  }

  cancelNewEvent(){
    this.addingEvent=false;
    this.cleanEvent();
  }

// ==================================================================
// existing events...


  setDayTime(event: Event){

    // convert from a firebase timestamp to Date (for day and time)

    var eD = new Date(event.StartDateTime.seconds*1000);
    var eDISO = new Date(eD.getTime() - (eD.getTimezoneOffset() * 60000)).toISOString();
    event.StartDateDay = eDISO.split('T')[0];
    event.StartDateDayTime = eD.toLocaleTimeString();

    var eE = new Date(event.EndDateTime.seconds*1000);
    var eEISO = new Date(eE.getTime() - (eE.getTimezoneOffset() * 60000)).toISOString();
    event.EndDateDay = eEISO.split('T')[0];
    event.EndDateDayTime = eE.toLocaleTimeString();

    this.saveState="busy";
    this.eventDocRef = this.afs.doc('events/'+event.id);
    this.eventDocRef.update(event).then(e => {
      this.saveState="done";
      this.editingEvent="";
      }
    )
  }

  setEvent(event: Event){
    this.saveState="busy";

    var newStamp = new Date(event.StartDateDay +"T"+event.StartDateDayTime).getTime() /1000;
    event.StartDateTime = new firestore.Timestamp(newStamp,0);

    var newEndStamp = new Date(event.EndDateDay +"T"+event.EndDateDayTime).getTime() /1000;
    event.EndDateTime = new firestore.Timestamp(newEndStamp,0);

    this.eventDocRef = this.afs.doc('events/'+event.id);
    this.eventDocRef.update(event).then(e => {
      this.saveState="done";
      this.editingEvent="";
      }
    )
  }

  editEvent(event){
    this.editingEvent=event;
    this.deleteR="";
    this.addingEvent=false;

    // fetch additional children copies...

  }

// ==================================================================
// deletions...
  
  cancelDeleteEvent(event){
    this.deleteR="";
  }

  deleteConfirm(event){
    this.editingEvent="";
    this.deleteR = event;
  }

  deleteEvent(event){
    this.eventDocRef = this.afs.doc('events/'+event);
    this.eventDocRef.delete().then(e => {
      this.saveState="done";
      this.editingEvent="";
      this.deleteR="";
      }
    )
  }


// ======================================================
// file upload and manipulation....

  
toggleHover(event: boolean) {
   this.isHovering = event;
}

startUpload(event: FileList,eventItem) {
    // The File object
    const file = event.item(0);
    this.uploadStatus = "in progress";
    this.uploadObject = eventItem;

    // Client-side validation example
    if (file.type.split('/')[0] !== 'image') { 
      console.error('unsupported file type :( ')
      return;
    }

    // The storage path
    const path = `uploads/events/${new Date().getTime()}_${file.name}`;

    // Totally optional metadata
    const customMetadata = { app: 'Curious Uploader' };

    // The main task
    this.task = this.storage.upload(path, file, { customMetadata });
    this.ref  = this.storage.ref(path);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();
    this.snapshot   = this.task.snapshotChanges();

    this.snapshot.pipe(
        finalize(() => {
          this.downloadURL = this.ref.getDownloadURL();
          this.downloadURL.subscribe(url => {
            this.uploadObject.ImgThumb[0]=url;
            this.uploadObject.ImgPath[0]=url;
            this.uploadStatus = "";
          });
        })
      )
      .subscribe();

  }

  // Determines if the upload task is active
  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes
  }



}
