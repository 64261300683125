import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

    loginState:boolean = false;

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.loginState ;
    }

    isLoggedIn(){
        return this.loginState;
    }

    setLoggedOut(){
        this.loginState=false;
    }

    setLoggedIn(){
        this.loginState=true;
    }


}