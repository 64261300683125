import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';

import { HomeComponent } from './home/home.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { CuriousfestivalComponent } from './curiousfestival/curiousfestival.component';
import { GalleryComponent } from './gallery/gallery.component';
import { NewsComponent } from './news/news.component';
import { ProjectsComponent } from './projects/projects.component';
import { TouringworksComponent } from './touringworks/touringworks.component';
import { QanComponent } from './qan/qan.component';
import { TrainingComponent } from './training/training.component';
import { AdminNewsComponent } from './admin/news/admin-news.component';
import { AdminLoginComponent } from './admin/login/admin-login.component';
import { AdminPageComponent } from './admin/pages/admin-page.component';
import { AdminEventsComponent } from './admin/events/admin-events.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about-us', component: AboutusComponent },
  { path: 'curious-festival', component: CuriousfestivalComponent },
  { path: 'touring-works', component: TouringworksComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'news', component: NewsComponent },
  { path: 'queer-arts-north', component: QanComponent},
  { path: 'training', component: TrainingComponent},

  { path: 'admin/login', component: AdminLoginComponent},

  { path: 'admin/edit-news', component: AdminNewsComponent , canActivate: [AuthGuard]},
  { path: 'admin/edit-curious-festival', component: AdminEventsComponent , canActivate: [AuthGuard]},
  { path: 'admin/edit-gallery', component: AdminNewsComponent, canActivate: [AuthGuard]},

  { path: 'admin/edit-home', redirectTo:'admin/edit-page/home'},
  { path: 'admin/edit-about-us', redirectTo:'admin/edit-page/about-us'},
  { path: 'admin/edit-touring-works', redirectTo:'admin/edit-page/touring-works'},
  { path: 'admin/edit-projects', redirectTo:'admin/edit-page/projects'},
  { path: 'admin/edit-queer-arts-north', redirectTo:'admin/edit-page/queer-arts-north'},
  { path: 'admin/edit-training', redirectTo:'admin/edit-page/training'},

  { path: 'admin/edit-page/:page', component: AdminPageComponent , canActivate: [AuthGuard]},
 
  { path: '**', component: HomeComponent }

];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule { }
